a = $ "#favorites-visibility a"

return unless a.length

isPrivate = $(@).text().indexOf("privat") != -1

# Tooltip
a.tooltip
  placement: "bottom"
  title: ->
    if isPrivate
      "Deine Favoriten und Zutaten sind nur für dich sichtbar."
    else
      "Deine Favoriten und Zutaten sind für alle sichtbar."

# Refresh nach Aktualisierung
a.on 'ajax:success', ->
  Analytics.event 'UserProfile', 'public favorites', (if isPrivate then 'disabled' else 'enabled'), ->
    document.location.href = document.location.pathname
