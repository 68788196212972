window.GoogleAnalyticsObject = 'ga'
ga = window.ga = ->
  (ga.q ||= []).push arguments
ga.l = 1 * new Date()

#
# Social Plugin-Helper übernommen von
# https://code.google.com/p/analytics-api-samples/source/browse/trunk/src/tracking/javascript/v5/social/ga_social_tracking.js?r=50
#

Analytics = window.Analytics =

  production: true

  # Initialize and load analytics
  init: (@options) ->
    @push ["create", @options.account]
    @set "anonymizeIp", true if @options.anonymize
    @load()

  # Load analytics
  load: ->
    $.ajax
      dataType: "script"
      url: '//www.google-analytics.com/analytics.js'
      cache: true

  # Proxy to ga
  push: (args) ->
    window.ga.apply window, args

  # https://developers.google.com/analytics/devguides/collection/analyticsjs/pages
  # Location, Page, Title
  pageview: (args) ->
    @push ["send", "pageview"].concat(args)

  # https://developers.google.com/analytics/devguides/collection/analyticsjs/custom-dims-mets
  set: (args) ->
    @push ["set"].concat(args)

  # https://developers.google.com/analytics/devguides/collection/analyticsjs/events
  # category, action, label, value
  # if the last argument is a function, then it is called after pushing the event.
  event: (args...) ->
    callback = args.pop() if typeof args[args.length-1] == 'function'

    @push ["send", "event"].concat(args)

    # Run callback, if given
    setTimeout callback, 100 if callback

  # https://developers.google.com/analytics/devguides/collection/analyticsjs/social-interactions
  social: (socialNetwork, socialAction, socialTarget, pagePath) ->
    @push ["send", "social", socialNetwork, socialAction, socialTarget, page: pagePath]

  ###
  Tracks Facebook likes, unlikes and sends by suscribing to the Facebook
  JSAPI event model. Note: This will not track facebook buttons using the
  iframe method.
  @param {string} opt_pagePath An optional URL to associate the social
  tracking with a particular page.
  ###
  trackFacebook: (opt_pagePath) ->
    return if !FB || !FB.Event || !FB.Event.subscribe

    subscribe = (event, action) =>
      FB.Event.subscribe event, (target) =>
        @social "facebook", action, target, opt_pagePath

    subscribe "edge.create", "like"
    subscribe "edge.remove", "unlike"
    subscribe "message.send", "send"
    return

  ###
  Binds Twitter Intent Events to a callback function that will handle
  the social tracking for Google Analytics. This function should be called
  once the Twitter widget.js file is loaded and ready.
  @param {string} opt_pagePath An optional URL to associate the social tracking with a particular page.
  ###
  trackTwitter: (opt_pagePath) ->
    intent_handler = (intent_event) =>
      @_trackTwitterHandler intent_event, opt_pagePath

    # bind twitter Click and Tweet events to Twitter tracking handler
    twttr.events.bind "click", intent_handler
    twttr.events.bind "tweet", intent_handler
    return

  ###
  Handles tracking for Twitter click and tweet Intent Events which occur
  everytime a user Tweets using a Tweet Button, clicks a Tweet Button, or
  clicks a Tweet Count. This method should be binded to Twitter click and
  tweet events and used as a callback function.
  Details here: http://dev.twitter.com/docs/intents/events
  @param {object} intent_event An object representing the Twitter Intent Event passed from the Tweet Button.
  @param {string} opt_pagePath An optional URL to associate the social tracking with a particular page.
  @private
  ###
  _trackTwitterHandler: (intent_event, opt_pagePath) ->
    opt_target = undefined #Default value is undefined
    if intent_event and intent_event.type is "tweet" or intent_event.type is "click"
      opt_target = @_extractParamFromUri(intent_event.target.src, "url")  if intent_event.target.nodeName is "IFRAME"
      socialAction = intent_event.type + ((if (intent_event.type is "click") then "-" + intent_event.region else "")) #append the type of click to action
      Analytics.social "twitter", socialAction, opt_target, opt_pagePath

  ###
  Extracts a query parameter value from a URI.
  @param {string} uri The URI from which to extract the parameter.
  @param {string} paramName The name of the query paramater to extract.
  @return {string} The un-encoded value of the query paramater. undefined if there is no URI parameter.
  @private
  ###
  _extractParamFromUri: (uri, paramName) ->
    return unless uri
    regex = new RegExp("[\\?&#]" + paramName + "=([^&#]*)")
    params = regex.exec(uri)
    if params? then unescape(params[1])
