self = window.Util = window.Util || {}

$.extend self,

  # HTML Escaping
  escapeHtml: (string) ->
    string.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")

  # Hebt gesuchte Begriffe hervor
  highlight: (string, term) ->
    matcher = new RegExp("("+$.ui.autocomplete.escapeRegex(term)+")", "ig" )
    @escapeHtml(string).replace(matcher, "<u>$1</u>")

$ ->
  $('body').addClass('no-touch') unless Modernizr?.touch
