
$.extend window.Application,
  loginRequired: ->
    $.get '/users/sign_in', { return_to: document.location.href }, (data) ->
      Application.modals.modal "<div class='modal-login'>"+data+"</div>"

  signedIn: ->
    !!$(document.body).data('role')

$(".login-required").click ->
  unless Application.signedIn()
    Application.loginRequired()
    @blur()
    return false

# Tracking auf remember_me-Checkbox
$(document).on 'change', "#user_remember_me, #session_remember_me", ->
  Analytics.event 'Session', this.id, (if this.checked then 'enabled' else 'disabled')

  if this.id == 'session_remember_me'
    $.post $(this).closest("form").attr("action"), remember_me: this.checked
