class Application.Map
  constructor: (@element) ->
    $e    = $(@element)
    @data = $e.data()

    if @data.center
      center  = @data.center.split(",")
      @center = [parseFloat(center[0]), parseFloat(center[1])]
    else
      # Mitte von Deutschland
      @center = [51,9]

    @zoom   ||= @data.zoom || 5
    @render()

  render: ->
    # Karten-Layer
    layer = new L.TileLayer \
      'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      maxZoom: 17
      minZoom: 5
      attribution: 'Map data © <a href="http://openstreetmap.org">OSM</a>'
      attributionControl: false

    # Karte
    @map = new L.Map @element,
      center: @center
      zoom:   @zoom
      layers: [layer]

    @map.attributionControl.setPrefix('');
    null
