
track = (action) ->
  Analytics.event 'Image', action

# Bearbeiten der Bildunterschrift
$(".thumbnail").on "click", "a[rel=edit]", (event) ->
  event.preventDefault()

  captionNode = $(this).closest("div").find(".text")
  oldCaption = captionNode.text()
  newCaption = prompt("Bitte gib eine neue Bildunterschrift ein:", oldCaption)

  # abgebrochen?
  return if newCaption==null

  $.ajax
    url: $(this).attr('href')
    type: 'PUT'
    data: 'image[caption]': newCaption
    success: ->
      captionNode.text(newCaption) #.effect("highlight", {}, 3000)

      # Tracking
      if !oldCaption && newCaption
        track 'caption added'
      else if oldCaption && !newCaption
        track 'caption removed'
      else if newCaption
        track 'caption changed'

# Löschen
$(".thumbnail").on "ajax:success", "[data-method=delete]", (event) ->
  track 'removed'
  document.location.reload()
