# Workaround für HTML5 Form-Validations
# Nicht-Sichtbare Felder werden leider trotzdem validiert
$(".fields *[required]").attr "required", false


$("form").on "click", '.add_child', (event) ->
  event.preventDefault()

  association = $(this).data("association")
  template = $("#" + association + "_fields_template").html()
  regexp = new RegExp("new_" + association, "g")
  new_id = new Date().getTime()

  $("#" + association).append template.replace(regexp, new_id)


$("form").on "click", '.remove_child', (event) ->
  event.preventDefault()

  $(this).prev("input[type=hidden]").val "1"
  $(this).parents(".fields").hide()
