#
# Hinzufügen von Zutaten in die eigene Bar per Autocompletion
#
# Braucht eine Reihe von `ul.ingredients li[data-id]` vorausgewählten
# Zutaten, und ein `form.favorite input#add-ingredient`, das als Anker
# für das Typeahead-Autocomplete dient.
#
$ ->
  return unless (list = $("ul.ingredients")).length
  return unless (favForm = $('form.favorite')).length
  return unless (input = favForm.find('#add-ingredient')).length

  cache =
    ingredients: null
    selected: {}

  $.getJSON '/zutaten.json?xhr', (data)->
    cache.ingredients = data

  list.find("li").each (i,li)=>
    cache.selected[$(li).data('id')] = true

  input.selectize
    valueField:   'id'
    labelField:   'name'
    searchField:  'name'
    sortField:    'name'
    create:       false
    maxItems:     1

    load: (query, callback)->
      return callback() unless query.length
      return callback() unless cache.ingredients

      callback cache.ingredients.reduce (memo, i)->
        memo.push(i) unless cache.selected[i.id]
        memo
      , []

  sel = input[0].selectize

  # Favorit speichern
  sel.on 'change', (val)->
    item_id = parseInt val, 10
    return if isNaN(item_id)

    $.ajax
      url: favForm.attr('action')
      type: "POST"
      data:
        render: 'favorite'
        favorite:
          subject_type: 'Ingredient'
          subject_id:   item_id
    .done (data, status, xhr)->
      $(data).appendTo(list) #.effect("highlight", {}, 3000)
      list.trigger('updated')
      sel.removeOption(val)
      Analytics.event 'Favorite Ingredient', 'added through favorites', item_id
      return
    return

  list.on 'updated', ->
    # Wenn die Liste verändert wurde:
    #
    # - Text aktualisieren,
    # - Such-Feld leeren
    $('#ingredients-intro').load document.location.pathname + "?xhr"
    sel.clear()
    return
  .on 'ajax:success', 'li a[data-method="delete"]', (e)->
    # Wenn ein Eintrag aus der Bar gelöscht wurde, diesen wieder in der
    # Auswahl verfügbar machen.
    li = $(e.target).closest('li')
    sel.addOption
      id:   li.data('id')
      name: li.data('name')
    return

  # versehentliches Abschicken verhindern
  favForm.on 'submit', (evt)->
    evt.preventDefault()
    return false
