#
# Hinzufügen von Zutaten zu einem Rezept
#
$ ->
  return unless (form = $("form.recipe")).length
  return unless (input = form.find('#add-ingredient')).length

  # abschicken des Formulars verhindern,
  # wenn return in einem amount-Feld gedrückt wird
  form.on "keypress", "input.amount", (event) ->
    event.preventDefault() if event.keyCode==13

  # Umformatierung von Brücken und Dezimalzahlen
  form.on "change", "input.amount", ->
    val = this.value.replace(",",".")
    this.value = switch val
      when "1/2" then 0.5
      when "1/4" then 0.25
      when "3/4" then 0.75
      else val

  cache =
    ingredients: null
    selected: {}

  $.getJSON '/zutaten.json?xhr', (data)->
    cache.ingredients = data

  input.selectize
    valueField:   'id'
    labelField:   'name'
    searchField:  'name'
    sortField:    'name'
    create:       false
    maxItems:     1

    load: (query, callback)->
      return callback() unless query.length
      return callback() unless cache.ingredients

      callback cache.ingredients.reduce (memo, i)->
        memo.push(i) unless cache.selected[i.id]
        memo
      , []

  sel = input[0].selectize
  sel.on 'change', (val)->
    item_id = parseInt val, 10
    return if isNaN(item_id)

    found = false
    $("ul.fields:visible .ingredient_id").each ->
      found ||= parseInt(this.value, 10) == item_id
    return if found

    for i in cache.ingredients
      item_name = i.name if i.id == item_id

    template = $("#recipe_ingredients_fields_template").html()

    # Zufällige ID setzen
    template = $(template.replace(/new_recipe_ingredients/g, Math.random().toString().split(".")[1]))

    template.find(".ingredient_id").val(item_id)
    template.find(".ingredient_name").text(item_name)

    $("#recipe_ingredients").append template
    template.find("input").first().focus()
    sel.clear()
    return
