
window.Application = window.Application || {}

modals = window.Application.modals =
  # Zeigt einen Bootstrap Modal Dialog an.
  #
  # Parameter:
  # content: Inhalt des Dialogs als jQuery-Objekt oder Html-String
  # options: Ein JavaScript-Object für die Optionen:
  #
  # - title:       Titel des Dialogs
  #                Wenn kein Titel angegeben, oder der Wert falsy ist, wird innerhalb des contents nach einem
  #                Header-Tag gesucht, und dessen Inhalt als Title angezeigt.
  #
  # - buttons:     Buttons für den Dialog
  #                Erwartet ein JavaScript-Objekt, welches als key den namen und als value einen callback beinhaltet.
  #                Der Callback wird beim Klick auf diesen Button im kontext des Buttons selbst ausgeführt. Beispiel siehe alert, confirm, prompt
  #
  # - closeButton: Wenn false, keinen Close-Button im Dialog anzeigen.
  #
  # - size:        Anpassung der Modal-Größe, erlaubte Werte: [modal-lg, modal-sm]
  #
  # andere Optionen werden an die modal() function von Bootstrap weitergeleitet.
  #
  modal: (content, options) ->
    modals.close()

    options  = options or {}
    title    = options.title
    size     = options.size
    $content = $(content)
    decor    = 2*72 + 60 # header- und footer-height, sowie container-padding

    # Titel suchen
    if !title && e = $content.find('.page-header')
      # Titel gefunden
      title = e.html()
      e.remove()

    if title
      _title = "<h2 class='modal-title'>#{title}</h2>"
    unless options.closeButton
      _close = '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>'

    container = $ """
      <div class="modal contained fade #{options.class}" tabindex="-1" role="dialog" style="#{options.style}">
        <div class="modal-dialog #{size}">
          <div class="modal-content">
            <div class="modal-header">#{_close || ''} #{_title}</div>
            <div class="modal-body"></div>
          </div>
        </div>
      </div>
    """
    container.data("content", "html") if options.content is "html"
    container.find(".modal-body").append($content).css('max-height', $(window).height()-decor)

    if buttons = options.buttons
      # normale Buttons
      footer = $('<div class="modal-footer" />').appendTo container.find('.modal-content')
      $.each buttons, (name, callback) ->
        a = $("<a href='#' class='btn'>#{name}</a>").click modals.preventDefault(callback)
        footer.prepend a
      primaryButton = container.find(".btn:last").addClass("btn-primary")
      container.on 'shown.bs.modal', ->
        primaryButton.focus()

    else if (formButtons = container.find('.remote-content > form .form-actions')).length == 1
      # Proxy-Buttons
      formButtons.hide()
      footer = $('<div class="modal-footer" />').appendTo container.find('.modal-content')
      footer.addClass('form-horizontal') if formButtons.parents('form').hasClass('form-horizontal')

      formButtons.find('.btn').each ->
        btn = $(this)
        if btn.is('input')
          b = $("<button class='#{btn.attr("class")}'>#{btn.val()}</button>")
        else
          b = $("<button class='#{btn.attr("class")}'>#{btn.html()}</button>")
        b.click -> btn.click()
        footer.append b

    container.modal options

  preventDefault: (func) ->
    (e) ->
      e.preventDefault()
      func.call this, $(this).closest(".modal")
      false

  wrapStringWithTag: (string, tagName="p") ->
    $ "<#{tagName}>",
      html: string.replace("\n\n", "</#{tagName}><#{tagName}>")

  # Lädt den Inhalt für ein Modalfenster per AJAX
  #
  # zusätzliche Optionen:
  # - loaded  Callback der bei Anzeige des Fensters ausgeführt wird
  remote: (url, options={}) ->
    options.content = "html"

    $.get url, (data) =>
      @remoteCallback data, options

  remoteCallback: (data, options) ->
    m = @modal "<div class='remote-content'>#{data}</div>", options
    options.loaded(m)  if options.loaded

  # Zeigt eine Nachricht mit einem OK-Button an
  # nimmt optional einen callback entgegen, welcher im kontext des OK-Buttons augeführt wird,
  # wenn dieser geklickt wird.
  alert: (message, callback) ->
    t = Application.$t('js.modals.alert')
    callback = $.noop unless callback
    buttons = {}
    buttons[t 'ok'] = ->
      callback.call this
      modals.close()

    @modal modals.wrapStringWithTag(message),
      title: t('title')
      closeButton: false
      buttons: buttons

  # Zeigt eine Nachricht zur Bestätigung mit Ok/Abbrechen-Schaltfläche
  #
  # Nimmt optional einen callback entgegen, welcher im Kontext der Buttons
  # augeführt wird, wenn dieser geklickt wird und mit der Antwort als Boolean
  # parametrisiert wird.
  confirm: (message, callback) ->
    t = Application.$t('js.modals.confirm')
    callback = $.noop unless callback
    buttons = {}
    buttons[t 'ok'] = ->
      callback.call this, true
      modals.close()
    buttons[t 'cancel'] = ->
      callback.call this, false
      modals.close()

    @modal modals.wrapStringWithTag(message),
      title: t('title')
      closeButton: false
      buttons: buttons

  # Schließt ein geöffnetes Modalfenster
  # Erwartet als Argument ein Callback, kein anderes Objekt (z.B. DOM-Element etc.)
  close: (closedCallbackHandler) ->
    closedCallbackHandler and $(".modal").on("hidden", closedCallbackHandler)
    $(".modal").modal("hide").remove()
    $(".modal-backdrop").remove()
    return



$(document).on 'click', 'a[rel=modal]', (event) ->
  $this = $(this)
  event.preventDefault()

  # Support für Pagination
  loaded = ->
    $modal     = this
    $modalBody = $modal.find '.modal-body'
    $modalBody.find('.pagination a').on 'click', (e) ->
      e.preventDefault()
      $modalBody.load $(this).attr('href'), ->
        loaded $modal

  modals.remote $this.attr("href"),
    title: $this.attr("data-title")
    loaded: loaded

  false
