profile = $("#profile section.show")

return unless profile[0]

newLinkFormSelector = 'form.new-link'
newLinkFormHTML = null

renderForm = (content) ->
  profile.html content

  # HTML-Code für das Formular sichern und Formular entfernen
  form = $(newLinkFormSelector)
  newLinkFormHTML = form[0].outerHTML
  form.remove()

# Formular anzeigen
profile.find(".toolbar").on "ajax:success", "a", (event,content) ->
  renderForm content
  Analytics.event 'UserProfile', 'edit'

# Speichern fehlerhaft
profile.on "ajax:error", "form", (event, xhr)->
  event.stopPropagation()
  renderForm xhr.responseText

# Speichern erfolgreich
profile.on "ajax:success", "form", ->
  document.location.reload()

# Link hinzufügen
profile.on "click", "button.add-link", (event) ->
  event.preventDefault()

  Application.modals.modal newLinkFormHTML,
    title: "Profil hinzufügen"
    buttons:
      "Hinzufügen": ->
        # Versteckten Submit-Button anklicken, damit die Validations ausgeführt werden
        form.find("input[type=submit]").click()
      "Abbrechen": ->
        Application.modals.close()


  form = $(newLinkFormSelector)
  urlInput = form.find("input[type=url]").focus()

  form.submit (event) ->
    event.preventDefault()
    links = $("ul.links")
    url   = urlInput.val()

    template = $ links.find("script.template").html().trim()
    template.find("input").val(url)
    template.find("a").attr(href: url).text(url)
    template.appendTo(links)

    Application.modals.close()

    Analytics.event 'UserProfile', 'link added'

# Link entfernen
profile.on "click", "form button.close", ->
  $(this).closest("li").remove()
  Analytics.event 'UserProfile', 'link removed'
