#
# Versteckt alle weiteren Absätze ab dem ersten Absatz eines
# autohide-Containers von zum Beispiel einer Biographie eines Interpreten und
# fügt dem zweiten Absatz einen Link zum Ausklappen der restlichen Absätze
# hinzu.
#
$.fn.autoHide = ->
  visibleParagraphs = 1
  paragraphs = $("p", this)

  # abbrechen, wenn nichts zu verstecken
  return if paragraphs.length <= visibleParagraphs

  # "mehr"-Link hinzufügen
  $(paragraphs[visibleParagraphs-1]).append(" &nbsp; <a href='#' class='autohide-more'><strong>weiterlesen …</strong></a>")

  # Übrigend absätze verstecken
  $(paragraphs.slice(visibleParagraphs)).hide()


$(document.body).on 'click', 'a.autohide-more', (event) ->
  event.preventDefault()

  # Absätze anzeigen
  $("p", this.parentNode.parentNode).show()

  # Link entfernen
  $(this).remove()

  # Tracking
  Analytics.event 'MoreLink', 'clicked'


$ ->
  $(".ingredient-text").autoHide()
