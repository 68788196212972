
buttons = $(".twitter-share-button, .twitter-follow-button")

# Abbrechen, wenn keine Buttons vorhanden
return if buttons.length == 0

twttr = window.twttr =
  _e: []
  ready: (e) ->
    @_e.push(e)

twttr.ready ->
  Analytics.trackTwitter()

$.getScript "https://platform.twitter.com/widgets.js"
