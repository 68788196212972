
# Zeigt einen einzelnen Marker an
class Application.SimpleMap extends Application.Map
  constructor: (@element) ->
    @zoom = 13

    super @element

    # Fügt einen einzelnen Marker hinzu
    L.marker(@center).addTo(@map).bindPopup(@data.content).openPopup();
