
# Kein Facebook laden, wenn Zugriff per localhost erfolgt (z.b. bei Integration Tests)
return if document.location.hostname == "127.0.0.1"

window.fbAsyncInit = ->
  FB.init
    appId: "255319981243599"
    xfbml: true

  Analytics.trackFacebook()


if (plugins = $(".fb-like-box, .fb-like")).length
  # Größe anpassen
  plugins.each ->
    $this = $(this)
    # geht nicht mit data()
    $this.attr 'data-width', $this.innerWidth()

  $("<div id='fb-root'></div>").appendTo document.body
  $.getScript "https://connect.facebook.net/de_DE/all.js"
