#
# Hinzufügen von Zutaten zu einem Rezept
#
$ ->
  return unless (form = $("#extended-search")).length

  # Anzeigen / Entfernen der Facets bei kleinen Auflösungen
  form.find("#filter-button").click (e) ->
    e.preventDefault()
    form.find("#filters").toggleClass('hidden-xs')

  cache =
    ingredients: null
    selected: {}

  $.getJSON '/zutaten.json?xhr', (data)->
    cache.ingredients = data

  # Gibt das Hidden-Field für die IDs zum übergebenen Textfeld zurück
  getField = (input) ->
    input.closest("section").find("input[type=hidden]")

  form.find("input.ingredient").each ->
    input = $(this)
    input.selectize
      valueField:   'id'
      labelField:   'name'
      searchField:  'name'
      sortField:    'name'
      create:       false
      maxItems:     1

      load: (query, callback)->
        return callback() unless query.length
        return callback() unless cache.ingredients

        callback cache.ingredients.reduce (memo, i)->
          memo.push(i) unless cache.selected[i.id]
          memo
        , []

    sel = input[0].selectize
    sel.on 'change', (val)->
      item_id = parseInt(val, 10)
      return if isNaN(item_id)

      hidden = getField(input)

      # nicht doppelt suchen
      found   = false
      usedIds = hidden.val().split(',').reduce (memo, i)->
        i = parseInt(i, 10)
        found ||= i == item_id
        memo.push(i) unless isNaN(i)
        memo
      , []

      return if found

      usedIds.push item_id
      hidden.val usedIds.join(',')
      for i in cache.ingredients
        item_name = i.name if i.id == item_id

      Analytics.event 'Search', hidden.attr('name') + ' added by autocomplete', item_name, ->
        # Formular absenden
        form.submit()

  # Tracking für Facet-Links
  form.on "click", "ul.facets a", (event) ->
    event.preventDefault()
    $this  = $(this)
    field  = getField($this)
    value  = $this.parent("li").text().trim().split("\n")[0]
    action = if $this.hasClass('close') then 'removed' else 'added'

    Analytics.event 'Search', field.attr('name') + ' ' + action, value, ->
      document.location.href = $this.attr('href')
