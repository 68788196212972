import "../../../../vendor/assets/javascripts/videojs.js"

$ ->
  new Video "video[data-video]"
  return

# `val` auf nächste X Sekunden runden, so dass viele Werte
# hinreichend genau gruppiert werden können
modRoundValue = (val, precision=5)->
  ~~(val/precision) * precision

class Video
  @instance = null

  @enableResolutionsPlugin = false

  @imasdk =
    # url: "//imasdk.googleapis.com/js/sdkloader/ima3#{'_debug' if Application.isDevelopment}.js"
    url: "//imasdk.googleapis.com/js/sdkloader/ima3.js"
    debug: false

  @playerDefaults =
    controls: true
    autoplay: false
    preload:  "auto"
    plugins:
      resolutions: Video.enableResolutionsPlugin

  constructor: (id, @ratio=16/9)->
    @video = $(id)
    return unless @video.length == 1

    Video.instance?.tearDown()
    Video.instance = this

    @container  = @video.parent()
    @data       = @video.data()

    @resizeTargets =
      parent: @container.parent()
      ima:    null

    $(window).on 'resize', =>
      @onResize()
      return
    @onResize()

    @blockerDetector (found)=>
      return @replace() if found

      # IMA SDK schon geladen
      if google?.ima?
        @init()
      else
        # IMA SDK nachladen
        $.ajax(url: Video.imasdk.url, dataType: 'script').done =>
          @init()
          return
      return
    return

  init: ->
    $.getJSON @videoUrl(), (playlist)=>
      for v in @convertPlaylist(playlist)
        dflt = if v["default"] == true then 'data-default="true"' else ""
        @video.append """<source src="#{v.src}" type="#{v.type}" data-res="#{v.res}" #{dflt} />"""

      w = ~~(@video.width())
      h = ~~(@video.width()/@ratio)

      vjsOptions = $.extend {}, Video.playerDefaults,
        width:  w
        height: h

      self = this
      videojs @video[0], vjsOptions, ->
        self.onPlayerReady(this)
        self.setupIma(this, w, h) if self.vastUrl()

        $.getJSON self.relatedVideosUrl(), (list)->
          self.setupEndcard(self.vjs, list)

        @play() if self.isAutoplay()
        return
      return

  replace: ->
    @video.replaceWith '
      <div class="ima-failed">
        <div>
          <p class="h1">
            <i class="fa fa-exclamation-triangle"></i>
            Dein AdBlocker verhindert die Videos-Wiedergabe
          </p>
          <p class="lead">Um das Video ansehen zu können, deaktiviere deinen AdBlocker.</p>
          <p class="lead">Vielen Dank</p>
        </div>
      </div>
    '
    return

  # AdBlockDetection
  blockerDetector: (callback)->
    img = $ '<img/>',
      src: '/advertisement.jpg'
      css:
        border:   '5px solid red'
        display:  'block'
        position: 'absolute'
        left:     '-10px'
        top:      '-10px'
        width:    '1px'
        height:   '1px'

    img.ready ->
      setTimeout ->
        callback !img.is(':visible')
        img.remove()
        return
      , 300
      return

    $('body').append img
    return

  convertPlaylist: (playlist)->
    w     = ~~(@container.width())
    list  = []

    for i, item of playlist
      list.push {
        type: item.type
        src:  item.src
        res:  item.quality
      }

    return if list.length == 0

    # Auflösung finden, die Runter-, aber nicht Hochskaliert werden muss
    candidate = "1080p" # Fallback
    if w < 720
      candidate = "720p"
    if w < 360
      candidate = "360p"

    if Video.enableResolutionsPlugin
      for i in [0...list.length]
        if list[i].res == candidate
          list[i].default = true
      return list
    else
      for i in [0...list.length]
        if list[i].res == candidate
          return [list[i]]


  videoUrl: ->
    "/v/#{@data.video}.json"

  isAutoplay: ->
    @data.autoplay || /autoplay=?/.test(location.href)

  tearDown: ->
    @tearedDown = true

  onResize: ->
    return if @tearedDown == true

    w = @resizeTargets.parent.width()
    h = ~~(w/@ratio)

    @vjs?.width(w)
    @vjs?.height(h)
    @resizeTargets.ima?.width(w)
    @resizeTargets.ima?.height(h)
    return

  vastUrl: ->
    @data.vastUrl || false

  relatedVideosUrl: ->
    "/v/#{@data.video}/related.json"

  onPlayerReady: (player)->
    Analytics.event 'Video', 'ready', player.src()

    @vjs = player.on 'play', ->
      Analytics.event 'Video', 'started', @src()
      return

    .on 'pause', ->
      Analytics.event 'Video', 'pause', 'at', modRoundValue(@currentTime())
      return

    .on 'seeked', ->
      Analytics.event 'Video', 'seek', 'to', modRoundValue(@currentTime())
      return

    .on 'ended', ->
      Analytics.event 'Video', 'finished', @src()
      return

    .on 'resolutionchange', (e)->
      if e.new_source
        Analytics.event 'Video', 'quality change', e.new_source["data-res"]
      return

    .on 'adserror', ->
      # Bug (?) in der google-ima-Integration abfangen: Wenn die Werbung
      # nicht geladen werden kann, muss das `player.ads`-Layer wieder
      # heruntergefahren werden.
      @trigger "adscanceled"
      @play() if @hasStarted() && @paused()
      return

    .one 'loadedmetadata', ->
      this.overlay
        overlays: [
          start:    0
          end:      this.duration()
          content:  document.createElement('div')
          align:    "cover"
        ]

      $('.vjs-overlay-cover').on 'click', ->
        if !player.hasStarted() || player.paused()
          player.play()
        else
          player.pause()
        return
      return

    return

  setupIma: (player, w, h)->
    player.ima
      id:               player.tag.id.replace(/_html5_api$/g, '')
      adTagUrl:         @vastUrl()
      debug:            Video.imasdk.debug
      nonLinearWidth:   w
      nonLinearHeight:  h

    player.ima.initializeAdDisplayContainer()
    player.ima.requestAds()

    @resizeTargets.ima = $('#ima-ad-container')

    @resizeTargets.ima.on 'click', =>
      if !player.hasStarted() || player.paused()
        player.play()
      else
        player.pause()
      return

  setupEndcard: (player, relatedVideos)->
    player.endcard
      count:          10,
      counter:        "counter"
      countdown:      "countdown"
      countdown_text: "Next video in:"
      endcard:        "player-endcard"
      related:        "related-content"
      next:           "next-video"

      getNext: $.noop
      getRelatedContent: (callback)->
        title = $("<h2>ähnliche Videos</h2>")
        list = $("<ul>")
        for v in relatedVideos
          list.append """
            <li>
              <div class="title"><a href="#{v.url}">#{v.title}</a></div>
              <a href="#{v.url}"><img src="#{v.poster}" /></a>
            </li>
          """

        setTimeout ->
          callback([title.get(0), list.get(0)])
          return
        , 0
        return

    return

Application.Video = Video
