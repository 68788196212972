import "digineo-cookie-consent"
import loadAnalytics from "./legacy/vendor/google/analytics-init"

window.DCCconfig = {
    cookies: {
        necessary: [{
            name: "_cocktaildb_session",
            provider: "Digineo GmbH",
            description: "Dieses Cookie dient der Besucherunterscheidung und der Anmeldung.",
            expires: "nach der Sitzung",
        }, {
            name: "consent",
            provider: "Digineo GmbH",
            description: "Wird verwendet um Ihre bevorzugten Cookie-Einstellungen zu speichern.",
            expires: "30 Tage",
        }],
        marketing: [{
            name: "_ga",
            provider: "Google",
            description: "Dieses Cookie dient der Besucherunterscheidung.",
            expires: "2 Jahre",
        },
        {
            name: "_gat",
            provider: "Google",
            description: "Wird von Google Analytics verwendet, um die Anforderungsrate einzuschränken.",
            expires: "1 Minute",
        },
        {
            name: "_gid",
            provider: "Google",
            description: "Registriert eine eindeutige ID, die verwendet wird, um statistische Daten dazu, wie der Besucher die Website nutzt, zu generieren.",
            expires: "1 Tag",
        }],
    },
    locale: "de",
    privacyUrl: "/imprint",
    siteNoticeUrl: "/imprint",
    cookieDomain: ".cocktaildatenbank.de",
    bootstrapMarketing() {
        loadAnalytics()
    },
    onMarketingChanged(value) {
        if (value) {
            loadAnalytics()
        }
    },
}

