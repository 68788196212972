
input = $("#user_name[data-check-uri]")
typingTimer = null
doneTypingInterval = 500

# abbrechen, wenn Element nicht vorhanden ist
return unless input[0]

# Zeigt eine Nachricht neben dem Eingabefeld an
help = (className, message) ->
  input.closest(".control-group").removeClass("error success").addClass(className)
  controls = input.closest(".controls")
  controls.find(".help-inline").remove()
  controls.append($('<span class="help-inline"></span>').html(message))


# Zeigt eine Fehlernachricht an
error = (message) ->
  help("error", message)

# Zeigt eine Erfolgsnachricht an
success = (message) ->
  help("success", message)

# Timeout für Eingabe ist abgelaufen
doneTyping = ->
  name = input.val()

  if name.length == 0
    return error "Bitte suche dir einen Namen aus."

  if name.length < 3
    return error "zu kurz (weniger als 3 Zeichen)"

  # Namen prüfen
  $.ajax
    url: input.data('check-uri')
    dataType: 'text'
    data:
      name: name
    complete: (xhr) ->
      switch xhr.status
        when 200
          success "<i class='fa fa-check'></i> noch frei"
        when 409
          error xhr.responseText
        else
          error "Unbekannter Fehler: " + xhr.status

# Timer starten
input.keyup ->
  clearTimeout typingTimer
  typingTimer = setTimeout(doneTyping, doneTypingInterval)


# Einmalig beim Laden ausführen
$ ->
  doneTyping()

  # Fokussieren, wenn Eingabefeld leer
  input.focus() if input.val()==''
