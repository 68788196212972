#
# Favoriten-Buttons
#

toolbar = $(".toolbar")
ingredients = $(".recipe-ingredients")

getSubject = (e) ->
  e.closest('[data-subject]').data('subject').split(" ")

# Aktualisiert den Favoriten-Zähler
updateCounter = (type, change) ->
  counter = $("#" + type + "-count")
  counter.text(parseInt(counter.text()) + change)

# Trackt ein Favoriten-Event
trackEvent = (e, action, doUpdate=true) ->
  subject = getSubject(e)
  Analytics.event 'Favorite ' + subject[0], action, subject[1]

  if doUpdate
    updateCounter subject[0], (if action.indexOf('added') == -1 then -1 else +1)

# Click-Handler für einen Hinzufügen-Link/Button
addFavorite = (event) ->
  event.preventDefault()
  $this = $(this)
  subject = getSubject($this)

  $this.attr('disabled', true)

  $.ajax
    url: $this.attr("href") || $this.data("uri")
    type: "POST"
    data:
      favorite:
        subject_type: subject[0]
        subject_id:   subject[1]

    complete: ->
      $this.attr('disabled', false)

    success: (data, status, xhr) ->
      $this.trigger('ajax:success', [data, status, xhr]);

    error: (xhr, status, error) ->
      trackEvent $this, "unauthorized", false if xhr.status == 401
      $this.trigger('ajax:error', [xhr, status, error]);


# Favoriten-Button in Toolbar
toolbar.on "click", ".favorite-button [rel=add]", addFavorite

# Hinzufügen-Link in der Zutaten-Liste
ingredients.on "click", "a[rel=add]", addFavorite


# Hinzufügen-Link bei ajax:success ersetzen
ingredients.on 'ajax:success', 'a', ->
  $this = $(this)
  trackEvent $this, 'added through ingredients'
  $this.replaceWith("<i class='fa fa-li fa-check text-success'></i>")

# Button bei ajax:success aktualisieren
toolbar.on "ajax:success", ".favorite-button", (event, data, status, xhr) ->
  $this = $(this)
  $data = $(data)
  trackEvent $this, (if $data.hasClass("active") then 'added' else 'removed')
  $this.replaceWith($data)


