toggleQuicksearch = ->
  return unless (input = $("input#quicksearch")).length

  if input[0].selectize
    input = input[0].selectize

  container = $('.search-open')

  $('.search-button a').on 'click', (e)->
    e.preventDefault()
    container.slideToggle ->
      input.focus() if container.is(':visible')

autocomplete = ->
  # Bei kleinen Auflösungen kein Autocompletion
  return if window.innerWidth < 480

  categories =
    Recipe:     'Rezepte'
    Ingredient: 'Zutaten'
    Seach:      'Profi-Suche'

  $("form.navbar-search").submit (event)->
    event.preventDefault()

    Analytics.event 'Quicksearch', 'submit', $(this).find("input[type=text]").val(), =>
      @submit()

  # Autocomplete

  return unless (input = $("input#quicksearch")).length

  input.selectize
    valueField:     'id'
    labelField:     'label'
    searchField:    'label'
    sortField:      'label'
    optgroupField:  'type'
    create:         false
    maxItems:       1

    load: (query, callback)->
      return callback() unless query.length

      baseList = [{
        href: "/search?q=#{encodeURIComponent query}"
        label: 'Profisuche'
        type:  'Search'
        value: 'search'
        id:    'search'
        label: query
      }]

      $.ajax
        url: "/quicksearch"
        data:
          q: query
      .done (data, status, xhr)->
        callback data.reduce (memo, datum)->
          datum.id = [datum.type, datum.value].join('-')
          memo.concat [datum]
        , baseList

      .fail (xhr, error, status)->
        callback baseList
      return

  sel = input[0].selectize
  for cat, label of categories
    sel.addOptionGroup cat, label: label

  sel.on 'change', (val)->
    if (item = sel.options[val])?
      # Tracking
      Analytics.event 'Quicksearch', 'select', item.label, ->
        location.href = item.href
    return

$ ->
  autocomplete()
  toggleQuicksearch()
