Application.dtpOptions =
  # https://eonasdan.github.io/bootstrap-datetimepicker/#options
  format: 'DD.MM.YYYY HH:mm'
  autoclose: true
  direction: "down"
  icons:
    time: "fa fa-clock-o"
    date: "fa fa-calendar"
    up:   "fa fa-chevron-up"
    down: "fa fa-chevron-down"

$(document).on 'focus', 'input[type=datetime], input[data-type^=date]', ->
  $(@).datetimepicker Application.dtpOptions
  return
