
# Darstellung der Marker als Cluster
class Application.ClusterMap extends Application.Map
  constructor: (@element) ->
    super @element

    self = this
    $.getJSON @data.cluster, (data) ->
      self.addMarkers(data)

  # Fügt die Marker aus dem Array hinzu
  addMarkers: (data) ->
    # Markers
    markers = new L.MarkerClusterGroup();
    for loc in data
      markers.addLayer( new L.Marker(new L.LatLng(loc[0], loc[1]), { title: loc[2] }) )
    @map.addLayer(markers)

    # Klick auf einen Punkt
    markers.on 'click', (a) ->
      # Nach der Location suchen
      form = $("form.search")
      form.find("input.query").val a.layer.options.title
      form.submit()
