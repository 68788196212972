
maps = $(".map")

return unless maps[0]

renderMaps = ->
  for map in maps
    if $(map).data('cluster')
      new Application.ClusterMap(map)
    else
      new Application.SimpleMap(map)

leafletVersion = '0.7.3'

# CSS einbinden
$("<link>", {rel:  "stylesheet", type: "text/css", href: "//cdn.leafletjs.com/leaflet-#{leafletVersion}/leaflet.css"}).appendTo("head")

# onload: Karten laden
$ ->
  $.getScript "//cdn.leafletjs.com/leaflet-#{leafletVersion}/leaflet.js", ->
    $.getScript "//leaflet.github.io/Leaflet.markercluster/dist/leaflet.markercluster.js", ->
      renderMaps()
