$ ->
  recipes = $("ul.recipes")

  recipes.on "click", "li", (event) ->
    target = $(event.target)

    return if target.attr('href') # Klick auf Link
    return if target.closest('[rel=modal]').length # Klick auf Modal-Button

    location.href = target.closest("li").find("strong a").attr('href')

  classes =
    'non-alcoholic': 'Dieser Cocktail ist alkoholfrei'
    'favorites-count': 'So oft ist dieses Rezept in den Favoriten enthalten'
    'favorites-count is-favorite': 'Dieses Rezept gehört zu deinen Favoriten'

  $("ul.recipes, #search-results").one "mouseover", (event) ->
    $(this).find('em').each ->
      $this = $(this)
      $this.tooltip
        title: classes[$this.attr('class')]
        container: 'body'
      return
    return

  return
