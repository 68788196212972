#
# Bewertungs-Funktion mit Kommentar
#

#
# Bewertungs-Funktion
#
$("#rating-score").raty
  cancel: true
  target: '#rating-hint'
  targetKeep: true

  score: ->
    $(this).data 'score'

  click: (score, event) ->
    $this = $(this)
    subject = $this.data('subject').split(" ")

    $.ajax
      url: $this.data("uri")
      type: "POST"
      data:
        rating:
          score: score
          subject_type: subject[0]
          subject_id:   subject[1]

      success: (data, xhr) ->
        $this.trigger 'score:changed', [score, data]

      error: (xhr, status, error) ->
        Analytics.event 'Rating', 'unauthorized' if xhr.status == 401

        $this.raty 'cancel'
        $this.trigger 'ajax:error', [xhr, status, error]
