
table   = $("table#event-sources")
uri     = table.data('uri')
timeout = null


# keine Ressourcen verschwenden
return unless table[0]


table.on "ajax:success", ->
  refresh()

refreshIfRequired = ->
  # solange eine Ladeanzeige sichtbar ist, soll gepollt werden
  if table.find("img[alt=loading]").length > 0
    start()
    true
  else
    false

# Startet das Polling
start = ->
  window.clearTimeout(timeout)
  timeout = window.setTimeout refresh, 2500

# Pollt und aktualisiert die Tabelle
refresh = ->
  $.get uri, (content) ->
    table.find("tbody").html content

    # Polling stoppen, wenn nicht mehr benötigt
    unless refreshIfRequired()
      # Seite komplett neu laden, weil sich wahrscheinlich
      # die sichtbare Events geändert haben
      document.location.reload()

# Einmalig beim Seitenaufruf ausführen
refreshIfRequired()
