toggleHeader = (header, scrollTop)->
  header.toggleClass 'header-fixed-shrink', scrollTop > 100

$ ->
  $window = $(window)
  $header = $(".header-fixed .header-static")

  $window.on 'scroll', ->
    toggleHeader $header, $window.scrollTop()
  toggleHeader $header, $window.scrollTop()
