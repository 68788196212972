
# Event-Tracking
trackEvent = (action, label) ->
  Analytics.event 'Rating', action, label


$("#rating-score").on 'score:changed', (event, score, data) ->
  if score
    trackEvent 'created', score
    # Formular einfügen, wenn es noch nicht existiert
    ratingText.element.html(data) unless ratingText.textarea()[0]
    ratingText.edit()
  else
    trackEvent 'deleted'
    ratingText.element.html('')

#
# Sorgt für das abwechselnde Anzeigen/Verstecken von Kommentar und Formular
#
ratingText =
  element: $("#rating-text")

  # Zeigt den eigenen Kommentar bzw. 'Kein Kommentar' an
  show: ->
    empty = @text().text() == ''
    @showEmpty(empty)
    @showText(!empty)
    @showForm(false)

  # Zeigt das Formular an
  edit: ->
    @showEmpty(false)
    @showText(false)
    @showForm(true)
    @textarea().focus()

  text: ->
    @element.find("p")
  textarea: ->
    @element.find("textarea")
  showEmpty: (show) ->
    @element.find(".empty").toggle(show)
  showText: (show) ->
    @text().toggle(show)
  showForm: (show) ->
    @element.find("form").toggle(show)

# Text erfolgreich gespeichert
ratingText.element.on "ajax:success", ->

  # Event-Tracking
  textarea = ratingText.textarea()
  newText  = textarea.val().trim()
  oldText  = textarea[0].defaultValue.trim()

  if newText != oldText
    if newText.length == 0
      trackEvent 'text removed'
    else if oldText.length == 0
      trackEvent 'text added'
    else
      trackEvent 'text modified'

  # Vorgabewert aktualisieren und Text anzeigen
  textarea[0].defaultValue = newText
  ratingText.text().text(newText)
  ratingText.show()

# Text soll bearbeitet werden
ratingText.element.on "click", ".empty, p", ->
  ratingText.edit()

# Key-Events im Textfeld
ratingText.element.on "keypress", "textarea", (event) ->
  switch event.keyCode
    # Escape-Taste
    when 27 then ratingText.show()
    # Return mit CTRL oder Shift
    when 13 then ratingText.element.find("form").submit() if event.shiftKey || event.ctrlKey

# Eingabe abbrechen
ratingText.element.on "click", ".close", ->
  ratingText.show()
