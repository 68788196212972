
return if window.innerWidth < 900 || Application.isDevelopment

$ ->
  # UserVoice-Widget
  $.getScript '//widget.uservoice.com/soT5cDKh4205vQGhMsBuw.js'

  # Klicks auf den Feedback-Button tracken
  $(document.body).on "click", "#uvTab", ->
    Analytics.event "Feedback", "click"
