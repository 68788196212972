export default loadAnalytics = ->
  # Wenn der Hostname nicht stimmt, dann Analytics nicht laden
  if document.location.hostname != 'www.cocktaildatenbank.de'
    Analytics.load = ->
      false

    if process.env.NODE_ENV == "test"
      # Im test-Environment die Daten speichern, um sie anschließend prüfen zu können
      Analytics.entries = []
      Analytics.push = (args...) ->
        @entries.push args
    else
      # In anderen Environments Daten nur loggen
      Analytics.push = (args...) ->
        $l "Analytics: " + args.join(", ")


  # Analytics starten
  Analytics.init
    account: 'UA-33058309-1'
    anonymize: true

  # Rolle des Users tracken per custom variable tracken
  Analytics.set 'dimension1', (document.body.getAttribute("data-role") || 'guest')

  Analytics.pageview document.body.getAttribute("data-page")
