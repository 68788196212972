relativeJump = ->
  header = $('.header-static').outerHeight() + 40
  window.scrollBy(0, -header)

$(document)
# .on 'click', 'form :submit', (e)->
#   # see http://stackoverflow.com/q/28022786
#   return unless (form = $(e.target).closest('form')).length == 1
#   return if !form[0].checkValidity || form[0].checkValidity()
#
#   setTimeout relativeJump, 50
#   return

.on 'click', 'a:not([data-toggle])', (e)->
  return unless href = $(e.target).attr('href')
  return unless /#/.test href

  setTimeout ->
    uri = [window.location.pathname, window.location.hash].join('')
    if uri.indexOf(href) >= 0
      relativeJump()
  , 50

$ ->
  return unless (window.location.hash).length
  relativeJump()
